import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="container" style={{ marginBottom: `1.45rem` }}>
    <div style={{ padding: `1.45rem 1.0875rem` }}>
      <h1>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
          The Lernen To Talk Show
        </Link>
        <br />
        +++++++++++++++++++++++++
      </h1>
      <em>(the talk show where the host is learning how to talk)</em>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
